.dashboard-page-main-container {
  min-width: 100%;
}

.dashboard-page-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  background-color: white;
}
.dashboard-page-nav {
  display: flex;
  align-items: center;
}

.quick-access-shortcut-card p,
.dashboard-page-nav p {
  margin-bottom: 0 !important;
}

.quick-access-shortcut-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.quick-access-shortcut-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 392px;
}

.quick-access-tables {
  display: flex;
  gap: 1rem;
  align-self: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

@media only screen and (max-width: 600px) {
  .quick-access-tables {
    flex-wrap: wrap;
  }
}
