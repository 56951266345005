.drawer-header-icon-text {
  display: flex !important;
  flex-direction: row;
  /* border: 1px solid red; */
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  cursor: pointer;
}
.MuiPaper-root {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

.dashboard-copyright {
  background-color: #2f4050;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
}

.css-1jg2hq1-MuiPaper-root-MuiAccordion-root:before {
  height: 0 !important;
}

.formik-separator {
  height: 3px;
  width: 100%;
  color: gray;
  background-color: gray;
  margin: 0;
}
