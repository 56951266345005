.app-bar-profile-section {
  display: flex;
  padding: 0.25rem;
  gap: 0.5rem;
  align-items: center;
}

.app-bar-profile-photo-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.app-bar-profile-photo-title img {
  width: 40px;
  height: auto;
  border-radius: 10px;
}

.app-bar-drop-down-btn {
  border-radius: 10px !important;
  background-color: #ebedf0 !important;
}
