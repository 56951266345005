body {
  background-color: #f5f7fa !important;
}
@media print {
  header,
  .dashboard-page-title,
  .MuiDrawer-root {
    display: none !important;
  }
  .print {
    margin: 0 !important;
    position: relative;
    top: -60px;
  }
  body {
    background-color: white !important;
  }
  .no-print {
    display: none !important;
  }
}
