.dashboard-page-nav-with-add {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .dashboard-page-nav-with-add {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start !important;
  }
}
