.shipment-vertical-dashed-line,
.shipment-vertical-solid-line {
  position: relative;
}

.shipment-vertical-solid-line::before {
  content: '';
  position: absolute;
  top: 21px;
  left: 9px;
  width: 1px;
  height: 59px;
  border-right: 1px solid #ccc;
  z-index: 1;
}
.shipment-vertical-dashed-line::before {
  content: '';
  position: absolute;
  top: 21px;
  left: 9px;
  width: 1px;
  height: 59px;
  border-right: 1px dashed #ccc;
  z-index: 1;
}

.shipment-tracking-info-text {
  font-size: 14px;
}
